import React from "react";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import { useQuery } from "@apollo/client";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import CollectionTicker from "./CollectionTicker";
import CollectionList from "./CollectionList";
import { STOCK_IMAGES } from "../../../queries";
import { Link } from "react-router-dom";

const CollectionModal = ({ setError, show, onHide, itemSku }) => {
  const collection = CollectionList();
  const item = collection.find((i) => i.sku === itemSku);
  const imagesResult = useQuery(STOCK_IMAGES, { variables: { search: itemSku, brand: "Valdilana" } });

  if (imagesResult.loading) {
    return (
      <div>
        <Container className="my-5 text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Container>
      </div>
    );
  }

  if (imagesResult.error) {
    return <div></div>;
  }

  const images = imagesResult.data.activeStockItemsPublic.slice(0, imagesResult.data.activeStockItemsPublic.length).sort(() => 0.5 - Math.random());
  let selectedImages = images.slice(0, 10);

  return (
    <div>
      <Modal show={show} onHide={onHide} size="lg" aria-labelledby="collectionItem" centered>
        <Modal.Body style={{ background: "#000" }}>
          <Container fluid style={{ height: "550px", background: "#000" }}>
            <Row>
              <Col xs={{ span: 4, offset: 0 }} className={"justify-center h-100 mx-0 px-0"}>
                <Link to={`/catalog/${item.sku}`}>
                  <img src={item.sticker} className="w-100" alt={item.sku} />
                </Link>
              </Col>
              <Col xs={{ span: 8, offset: 0 }} className={"h-100 mx-0 px-0"}>
                <CollectionTicker itemImages={selectedImages} />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CollectionModal;
