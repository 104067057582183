import React from "react";
import Container from "react-bootstrap/Container";
import Summary from "./Summary";
import CollectionSummary from "./CollectionSummary";

const LandingPage = ({ setError }) => {
  return (
    <div>
      <Container fluid className="p-0">
        <Summary />
      </Container>
      <Container fluid className="p-0">
        <CollectionSummary />
      </Container>
    </div>
  );
};

export default LandingPage;
