import { gql } from "@apollo/client";

export const NEW_CONTACT = gql`
  mutation newContact($name: String!, $company: String, $email: String!, $type: String!, $country: String!, $message: String!, $entity: String!) {
    addContact(name: $name, company: $company, email: $email, country: $country, type: $type, message: $message, entity: $entity) {
      id
    }
  }
`;

export const STOCK_ITEM = gql`
  query findStockItemWithQty($SKU: String!, $brand: String) {
    findStockItemWithQty(SKU: $SKU, brand: $brand) {
      SKU
      brand
      description
      weight
      countryOfOrigin
      composition
      width
      unitOfMeasure
      metersPerKG
      salesType
      imgURI
      isActive
      qtyInStock
      qtyInTransit
      id
    }
  }
`;

export const STOCK_CATALOG = gql`
  query activeStockItemsPublic($brand: String, $search: String) {
    activeStockItemsPublic(brand: $brand, search: $search) {
      SKU
      brand
      description
      weight
      imgURI
    }
  }
`;

export const STOCK_IMAGES = gql`
  query activeStockItemsPublic($brand: String, $search: String) {
    activeStockItemsPublic(brand: $brand, search: $search) {
      SKU
      imgURI
    }
  }
`;
