import React from "react";
import { useParams, Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { STOCK_CATALOG } from "../../../queries";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";

const StockCatalog = ({ setError }) => {
  const search = useParams().search;
  const filter = search ? search : "";

  var stockItemQuery;

  stockItemQuery = useQuery(STOCK_CATALOG, { variables: { search: filter, brand: "Valdilana" } });

  if (stockItemQuery.loading) {
    return (
      <div>
        <Container fluid className="py-5 min-vh-100 text-center" style={{ backgroundColor: "#000" }}>
          <Row className="align-items-center my-5 py-5">
            <Col xs={{ span: 10, offset: 1 }}>
              <Row className="text-center py-5 small">
                <Col>
                  <Spinner animation="border" variant="light" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  if (stockItemQuery.error) {
    return (
      <Container fluid className="py-5 text-center text-light min-vh-100" style={{ backgroundColor: "#000" }}>
        <div className="my-5 py-5">Something went wrong, please try again later</div>
      </Container>
    );
  }

  const stockItems = stockItemQuery.data.activeStockItemsPublic;

  if (!stockItems) {
    return (
      <div>
        <Container fluid className="py-5 text-light min-vh-100" style={{ backgroundColor: "#000" }}>
          <Row className="align-items-center my-5 py-5">
            <Col xs={{ span: 10, offset: 1 }}>
              <Row className="text-center py-5 small">
                <Col>catalog</Col>
              </Row>
              Items not Found!
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  return (
    <div>
      <Container fluid className="pt-5 text-light" style={{ backgroundColor: "#000" }}>
        <Row>
          <Col xs={{ span: 10, offset: 1 }}>
            <Row className="text-center mt-5 pt-5 small">
              <Col>
                <Link to="/catalog" className="text-decoration-none text-light">
                  catalog
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Container fluid className="min-vh-100">
        <Row className="my-5">
          {stockItems.map((stockItem) => {
            let imgURI = `https://metamoda-public.s3.me-central-1.amazonaws.com/media/sm/${stockItem.SKU}.JPG`;
            return (
              <Col key={stockItem.SKU} xs={{ span: 12, offset: 0 }} sm={{ span: 6, offset: 0 }} md={{ span: 6, offset: 0 }} lg={{ span: 4, offset: 0 }} className="my-5">
                <Link to={`/${stockItem.SKU}`} className="text-decoration-none text-light">
                  <Container className="align-items-start">
                    <Row className="align-items-start">
                      <img
                        src={imgURI}
                        alt={stockItem.SKU}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = "/placeholdersm.jpg";
                        }}
                        className="text-start"
                      />
                    </Row>
                    <Row className="text-start display-6">
                      <Col>{stockItem.SKU}</Col>
                    </Row>
                    <Row className="text-start">
                      <Col>{stockItem.description}</Col>
                    </Row>
                    <Row className="text-start">
                      <Col>{stockItem.weight} g/mt</Col>
                    </Row>
                  </Container>
                </Link>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default StockCatalog;
