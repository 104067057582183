const CollectionList = () => {
  const collection = [
    {
      sku: "1100",
      sticker: "/collection/1100Sticker.png",
      images: ["vlHero1", "vlHero2", "vlHero3", "vlHero4", "vlHero5"],
    },
    {
      sku: "1300",
      sticker: "/collection/1300Sticker.png",
      images: ["vlHero1", "vlHero2", "vlHero3", "vlHero4", "vlHero5"],
    },
    {
      sku: "5100",
      sticker: "/collection/5100Sticker.png",
      images: ["vlHero1", "vlHero2", "vlHero3", "vlHero4", "vlHero5"],
    },
    {
      sku: "6100",
      sticker: "/collection/6100Sticker.png",
      images: ["vlHero1", "vlHero2", "vlHero3", "vlHero4", "vlHero5"],
    },
    {
      sku: "6150",
      sticker: "/collection/6150Sticker.png",
      images: ["vlHero1", "vlHero2", "vlHero3", "vlHero4", "vlHero5"],
    },
    {
      sku: "9100",
      sticker: "/collection/9100Sticker.png",
      images: ["vlHero1", "vlHero2", "vlHero3", "vlHero4", "vlHero5"],
    },
  ];

  return collection;
};
export default CollectionList;
