import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Terms from "./Terms";
import Privacy from "./Privacy";

const Legal = ({ setError }) => {
  const terms = Terms();
  const privacy = Privacy();

  return (
    <div>
      <Container fluid className="py-5 text-light min-vh-100" style={{ backgroundColor: "#000" }}>
        <Row className="align-items-center mt-5">
          <Col xs={{ span: 12, offset: 0 }} className="text-center">
            <AnimationOnScroll duration={2} animateOnce={true} animateIn="animate__fadeIn">
              <Row className="my-5 pt-5 small">
                <Col>legal</Col>
              </Row>
            </AnimationOnScroll>
          </Col>
        </Row>
        <AnimationOnScroll duration={2} delay={250} animateOnce={true} animateIn="animate__fadeIn">
          <Row id="termsOfUse" className="align-items-center">
            <Col xs={{ span: 10, offset: 1 }} sm={{ span: 10, offset: 1 }} className=" text-center lead">
              <Row className="">
                <Col className="small">Terms of Use</Col>
              </Row>
              <Row className="my-3 small">
                <a className="text-decoration-none text-light" href="#privacyPolicy">
                  <Col>Skip to Privacy Policy</Col>
                </a>
              </Row>
              <Row className="my-5">
                <Col className="text-start">{terms}</Col>
              </Row>
            </Col>
          </Row>
        </AnimationOnScroll>
        <AnimationOnScroll duration={2} delay={0} animateOnce={true} animateIn="animate__fadeIn">
          <Row id="privacyPolicy" className="align-items-center">
            <Col xs={{ span: 10, offset: 1 }} sm={{ span: 10, offset: 1 }} className=" text-center lead">
              <Row className="my-5">
                <Col className="small">Privacy Policy</Col>
              </Row>
              <Row className="my-5">
                <a className="text-decoration-none text-light" href="#termsOfUse">
                  <Col className="small">Back to Terms of Use</Col>
                </a>
              </Row>
              <Row className="my-5">
                <Col className="text-start">{privacy}</Col>
              </Row>
            </Col>
          </Row>
        </AnimationOnScroll>
      </Container>
    </div>
  );
};

export default Legal;
