import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { AnimationOnScroll } from "react-animation-on-scroll";
import CollectionModal from "./CollectionModal";
import ContactForm from "../../ContactForm";

const Collection = ({ setError }) => {
  const [waitlistModalShow, setWaitlistModalShow] = useState(false);
  const [collectionModalShow, setCollectionModalShow] = useState(false);
  const [sku, setSku] = useState("1100");

  return (
    <div>
      <Container fluid className="py-5 text-light min-vh-100" style={{ backgroundColor: "#000" }}>
        <Row className="align-items-center my-5 py-5">
          <Col xs={{ span: 10, offset: 1 }}>
            <Row className="text-center py-5 small">
              <Col>collection</Col>
            </Row>
            <Row className="my-5">
              <Col>
                <AnimationOnScroll duration={2} animateOnce={true} delay={500} animateIn="animate__fadeIn">
                  <Container
                    className="lead fingerCursor my-4 p-1"
                    onClick={() => {
                      setSku("1100");
                      setCollectionModalShow(true);
                    }}
                  >
                    <Row>
                      <Col>
                        <span className="fw-bold">1100</span> /
                      </Col>
                    </Row>
                    <Row>
                      <Col>SELECTION /</Col>
                    </Row>

                    <Row>
                      <Col>SUPER 110's /</Col>
                    </Row>
                    <Row>
                      <Col>PRUNELLE</Col>
                    </Row>
                  </Container>
                </AnimationOnScroll>
              </Col>
              <Col>
                <AnimationOnScroll duration={2} animateOnce={true} delay={600} animateIn="animate__fadeIn">
                  <Container
                    className=" fingerCursor lead my-4 p-1"
                    onClick={() => {
                      setSku("1300");
                      setCollectionModalShow(true);
                    }}
                  >
                    <Row>
                      <Col>
                        <span className="fw-bold">1300</span> /
                      </Col>
                    </Row>
                    <Row>
                      <Col>LUXURY /</Col>
                    </Row>

                    <Row>
                      <Col>SUPER 130's /</Col>
                    </Row>
                    <Row>
                      <Col>BATAVIA</Col>
                    </Row>
                  </Container>
                </AnimationOnScroll>
              </Col>
              <Col>
                <AnimationOnScroll duration={2} animateOnce={true} delay={700} animateIn="animate__fadeIn">
                  <Container
                    className="fingerCursor lead my-4 p-1"
                    onClick={() => {
                      setSku("5100");
                      setCollectionModalShow(true);
                    }}
                  >
                    <Row>
                      <Col>
                        <span className="fw-bold">5100</span> /
                      </Col>
                    </Row>
                    <Row>
                      <Col>ACTIVE /</Col>
                    </Row>

                    <Row>
                      <Col>SUPER 120's /</Col>
                    </Row>
                    <Row>
                      <Col>WASHABLE</Col>
                    </Row>
                  </Container>
                </AnimationOnScroll>
              </Col>
              <Col className="fingerCursor">
                <AnimationOnScroll duration={2} animateOnce={true} delay={800} animateIn="animate__fadeIn">
                  <Container
                    className="fingerCursor lead my-4 p-1"
                    onClick={() => {
                      setSku("6100");
                      setCollectionModalShow(true);
                    }}
                  >
                    <Row>
                      <Col>
                        <span className="fw-bold">6100</span> /
                      </Col>
                    </Row>
                    <Row>
                      <Col>ACTIVE /</Col>
                    </Row>

                    <Row>
                      <Col>SUPER 120's /</Col>
                    </Row>
                    <Row>
                      <Col>LIGHTWEIGHT</Col>
                    </Row>
                  </Container>
                </AnimationOnScroll>
              </Col>
              <Col>
                <AnimationOnScroll duration={2} animateOnce={true} delay={900} animateIn="animate__fadeIn">
                  <Container
                    className="fingerCursor lead my-4 p-1"
                    onClick={() => {
                      setSku("6150");
                      setCollectionModalShow(true);
                    }}
                  >
                    <Row>
                      <Col>
                        <span className="fw-bold">6150</span> /
                      </Col>
                    </Row>
                    <Row>
                      <Col>ACTIVE /</Col>
                    </Row>

                    <Row>
                      <Col>SUPER 120's /</Col>
                    </Row>
                    <Row>
                      <Col>STRETCH</Col>
                    </Row>
                  </Container>
                </AnimationOnScroll>
              </Col>
              <Col>
                <AnimationOnScroll duration={2} animateOnce={true} delay={1000} animateIn="animate__fadeIn">
                  <Container
                    className="fingerCursor lead my-4 p-1"
                    onClick={() => {
                      setSku("9100");
                      setCollectionModalShow(true);
                    }}
                  >
                    <Row>
                      <Col>
                        <span className="fw-bold">9100</span> /
                      </Col>
                    </Row>
                    <Row>
                      <Col>EXCLUSIVE /</Col>
                    </Row>

                    <Row>
                      <Col>SUPER 150's /</Col>
                    </Row>
                    <Row>
                      <Col>FEATHERLIGHT</Col>
                    </Row>
                  </Container>
                </AnimationOnScroll>
              </Col>
            </Row>
            <Row ClassName="w-100">
              <Col xs={{ span: 6, offset: 3 }} sm={{ span: 4, offset: 2 }} className="text-center align-items-center">
                <AnimationOnScroll duration={2} animateOnce={true} delay={1200} animateIn="animate__fadeIn">
                  <Button onClick={() => setWaitlistModalShow(true)} size="lg" className="my-3" variant="outline-light">
                    CONTACT
                  </Button>
                </AnimationOnScroll>
              </Col>
              <Col xs={{ span: 6, offset: 3 }} sm={{ span: 4, offset: 0 }} className="text-center align-items-center">
                <AnimationOnScroll duration={2} animateOnce={true} delay={1200} animateIn="animate__fadeIn">
                  <Link to="/catalog" className="text-light text-decoration-none">
                    <Button size="lg" className="my-3" variant="outline-light">
                      CATALOG
                    </Button>
                  </Link>
                </AnimationOnScroll>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <ContactForm show={waitlistModalShow} onHide={() => setWaitlistModalShow(false)} setError={setError} />
      <CollectionModal show={collectionModalShow} onHide={() => setCollectionModalShow(false)} itemSku={sku} setError={setError} />
    </div>
  );
};

export default Collection;
