import React from "react";

const CollectionItem = ({ image }) => {
  var imgURI = `https://metamoda-public.s3.me-central-1.amazonaws.com/media/${image}.JPG`;
  return (
    <div>
      <img
        style={{ height: "550px", width: "100%" }}
        src={imgURI}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = "/placeholder.jpg";
        }}
        alt="placeholder"
      />
    </div>
  );
};

export default CollectionItem;
