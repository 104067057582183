import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ContactForm from "../ContactForm";

const LandingButtons = ({ setError }) => {
  const [waitlistModalShow, setWaitlistModalShow] = useState(false);

  return (
    <div>
      <Container fluid className="position-absolute top-50 start-50 translate-middle">
        <Row>
          <Col className="text-center" xs={{ span: 6, offset: 3 }} sm={{ span: 3, offset: 3 }}>
            <Button onClick={() => setWaitlistModalShow(true)} size="md" className="my-3" variant="outline-light">
              CONTACT
            </Button>
          </Col>
          <Col className="text-center" xs={{ span: 6, offset: 3 }} sm={{ span: 3, offset: 0 }}>
            <Link to="/catalog">
              <Button size="md" className="my-3" variant="outline-light">
                CATALOG
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>

      <ContactForm show={waitlistModalShow} onHide={() => setWaitlistModalShow(false)} setError={setError} />
    </div>
  );
};

export default LandingButtons;
