import React from "react";
import { Container } from "react-bootstrap";
import Marquee from "react-marquee-slider";
import SwatchItem from "./SwatchItem";
import Spinner from "react-bootstrap/Spinner";
import useWindowDimensions from "../../WindowDimensions";
import { useQuery } from "@apollo/client";
import { STOCK_IMAGES } from "../../../queries";

const SwatchTicker = ({ setError }) => {
  const { height, width } = useWindowDimensions();
  const tickerSpeed = width >= 576 ? 6 : 3;
  const imagesResult = useQuery(STOCK_IMAGES, { variables: { brand: "Valdilana" } });

  if (imagesResult.loading) {
    return (
      <div>
        <Container className="my-5 text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Container>
      </div>
    );
  }

  if (imagesResult.error) {
    return <div></div>;
  }

  const images = imagesResult.data.activeStockItemsPublic.slice(0, imagesResult.data.activeStockItemsPublic.length).sort(() => 0.5 - Math.random());
  let selectedImages = images.slice(0, 20);

  return (
    <div>
      <Container fluid className="mx-0 p-0" style={{ marginTop: "150px", marginBottom: "20px", height: "300px" }}>
        <Marquee velocity={tickerSpeed}>
          {selectedImages.map((i) => (
            <SwatchItem item={i} key={i} />
          ))}
        </Marquee>
      </Container>
    </div>
  );
};

export default SwatchTicker;
