import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const SwatchItem = ({ setError, item }) => {
  var imgURI = `https://metamoda-public.s3.me-central-1.amazonaws.com/media/sm/${item.SKU}.JPG`;

  return (
    <div>
      <Container>
        <Link to={`./${item.SKU}`}>
          <img
            className="swatch-image"
            src={imgURI}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "/placeholdersm.jpg";
            }}
            alt={item}
          />
        </Link>
      </Container>
    </div>
  );
};

export default SwatchItem;
