import React from "react";
import Marquee from "react-marquee-slider";
import CollectionItem from "./CollectionItem";
import useWindowDimensions from "../../WindowDimensions";
import { Link } from "react-router-dom";

const CollectionTicker = ({ setError, show, onHide, itemImages }) => {
  const { height, width } = useWindowDimensions();
  const tickerSpeed = width >= 576 ? 4 : 0.8;
  const item = itemImages;
  return (
    <div>
      <Marquee velocity={tickerSpeed}>
        {item.map((i) => (
          <Link to={`/${i.SKU}`}>
            <CollectionItem image={i.SKU} key={i.SKU} />
          </Link>
        ))}
      </Marquee>
    </div>
  );
};

export default CollectionTicker;
