import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { AnimationOnScroll } from "react-animation-on-scroll";

const CollectionSummary = ({ setError }) => {
  return (
    <div>
      <Container fluid className="hero4 m-0 p-0 min-vh-100 text-light" style={{ backgroundColor: "#000" }}>
        <Row className="min-vh-100 align-items-center">
          <Col xs={{ span: 6, offset: 3 }} sm={{ span: 5, offset: 0 }} className="display-1 text-center">
            <AnimationOnScroll duration={3} animateOnce={true} animateIn="animate__fadeIn">
              <Link to="./collection" className="text-light text-decoration-none">
                OUR COLLECTION
              </Link>
            </AnimationOnScroll>
          </Col>
          <Col xs={{ span: 6, offset: 3 }} sm={{ span: 7, offset: 0 }} className="text-center lead">
            <Row className="my-5">
              <Col>
                <AnimationOnScroll duration={3} delay={250} animateOnce={true} animateIn="animate__fadeIn">
                  Our collection spans the classics, technowools, and super-limited special items
                </AnimationOnScroll>
              </Col>
            </Row>
            <Row className="my-5">
              <Col>
                <AnimationOnScroll duration={3} delay={500} animateOnce={true} animateIn="animate__fadeIn">
                  Choose from our range of Super 110's to Super 180's
                </AnimationOnScroll>
              </Col>
            </Row>
            <Row className="my-5">
              <Col>
                <AnimationOnScroll duration={3} delay={750} animateOnce={true} animateIn="animate__fadeIn">
                  All of our items are 100% Italian
                </AnimationOnScroll>
              </Col>
            </Row>
            <Row className="my-5">
              <Col>
                <AnimationOnScroll duration={3} delay={750} animateOnce={true} animateIn="animate__fadeIn">
                  <Link to="/collection" className="text-light">
                    View the Collection
                  </Link>
                </AnimationOnScroll>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CollectionSummary;
