import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Link } from "react-router-dom";

import ContactForm from "../ContactForm";

const LandingAbout = ({ setError }) => {
  const [waitlistModalShow, setWaitlistModalShow] = useState(false);

  return (
    <div>
      <Container fluid className="py-5 text-light min-vh-100" style={{ backgroundColor: "#000" }}>
        <Row className="align-items-center mt-5">
          <Col xs={{ span: 12, offset: 0 }} className="text-center">
            <Row className="my-5 pt-5">
              <Col className="small">
                <AnimationOnScroll duration={2} animateOnce={true} animateIn="animate__fadeIn">
                  about
                </AnimationOnScroll>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col xs={{ span: 8, offset: 2 }} sm={{ span: 6, offset: 3 }} className=" text-center lead">
            <Row className="my-5">
              <Col>
                <AnimationOnScroll duration={2} animateOnce={true} delay={250} animateIn="animate__fadeIn">
                  We believe that the best fashion starts with the fabric.
                </AnimationOnScroll>
              </Col>
            </Row>
            <Row className="my-5">
              <Col>
                <AnimationOnScroll duration={2} animateOnce={true} delay={500} animateIn="animate__fadeIn">
                  We have built a collection that reflects our fashion forward values, attention to detail, and great concern for the environment and animals.
                </AnimationOnScroll>
              </Col>
            </Row>
            <AnimationOnScroll duration={2} animateOnce={true} delay={750} animateIn="animate__fadeIn">
              <Row className="my-5">
                <Col>We want the process of choosing a fabric to be as enjoyable as the first time wearing new clothes.</Col>
              </Row>
              <Row className="mt-5 mb-2 pt-5">
                <Col className="fw-bold">Let's take fashion forward.</Col>
              </Row>
              <Row className="mb-5">
                <Col xs={{ span: 6, offset: 3 }} sm={{ span: 4, offset: 2 }} className="text-center align-items-center">
                  <AnimationOnScroll duration={2} animateOnce={true} delay={0} animateIn="animate__fadeIn">
                    <Button onClick={() => setWaitlistModalShow(true)} size="lg" className="my-3" variant="outline-light">
                      CONTACT
                    </Button>
                  </AnimationOnScroll>
                </Col>
                <Col xs={{ span: 6, offset: 3 }} sm={{ span: 4, offset: 0 }} className="text-center align-items-center">
                  <AnimationOnScroll duration={2} animateOnce={true} delay={0} animateIn="animate__fadeIn">
                    <Link to="/catalog" className="text-light text-decoration-none">
                      <Button size="lg" className="my-3" variant="outline-light">
                        CATALOG
                      </Button>
                    </Link>
                  </AnimationOnScroll>
                </Col>
              </Row>
            </AnimationOnScroll>
          </Col>
        </Row>
      </Container>

      <ContactForm show={waitlistModalShow} onHide={() => setWaitlistModalShow(false)} setError={setError} />
    </div>
  );
};

export default LandingAbout;
