import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";
import SwatchTicker from "./swatchTicker/SwatchTicker";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Link } from "react-router-dom";

const Summary = ({ setError }) => {
  return (
    <div>
      <Container fluid className="m-0 p-0 min-vh-100 text-light" style={{ backgroundColor: "#000" }}>
        <Container fluid className="p-0">
          <Link to="./collection">
            <SwatchTicker />
          </Link>
        </Container>

        <Row>
          <Col className="lead text-center">
            <AnimationOnScroll duration={3} animateOnce={true} animateIn="animate__fadeIn">
              <span className="fw-bold">We are the next generation of fabrics.</span> We bring together <span className="fw-bold fst-italic">centuries</span> of experience and a fresh approach to deliver an unparalleled fashion experience
            </AnimationOnScroll>
          </Col>
        </Row>

        <Row className="my-0 align-items-bottom px-5">
          <Col xs={{ span: 12, order: 2, offset: 0 }} md={{ span: 4, order: 1, offset: 0 }} className="mw-25 text-center">
            <AnimationOnScroll duration={2} delay={500} animateOnce={true} animateIn="animate__fadeIn">
              <Row className="my-5">
                <Col className="display-4">LUXURIOUS</Col>
              </Row>
              <Row className="my-5">
                <Col className="lead">We manufacture our fabrics in the Italian countryside, with a combination of traditional artisan techniques and high technology manufacturing</Col>
              </Row>
            </AnimationOnScroll>
          </Col>

          <Col xs={{ span: 12, order: 1, offset: 0 }} md={{ span: 4, order: 2, offset: 0 }} className=" mw-25 text-center">
            <AnimationOnScroll duration={2} animateOnce={true} animateIn="animate__fadeIn">
              <Row className="my-5">
                <Col className="display-4">FASHIONABLE</Col>
              </Row>
              <Row className="my-5">
                <Col className="lead">We do not follow trends, we start them. Our colours and designs are painstakingly designed to be ahead of the market</Col>
              </Row>
            </AnimationOnScroll>
          </Col>

          <Col xs={{ span: 12, order: 3, offset: 0 }} md={{ span: 4, order: 3, offset: 0 }} className="mw-25 text-center">
            <AnimationOnScroll duration={2} delay={500} animateOnce={true} animateIn="animate__fadeIn">
              <Row className="my-5">
                <Col className="display-4">SUSTAINABLE</Col>
              </Row>
              <Row className="my-5">
                <Col className="lead">Our fabrics are fully traceable to the individual sheep and come from mills with the highest standards and attention to the animals and environment</Col>
              </Row>
            </AnimationOnScroll>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Summary;
