import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

const LandingFooter = ({ setError }) => {
  return (
    <div>
      <Container fluid className="justify-content-center text-light" style={{ backgroundColor: "#000" }}>
        <Row className="py-3 small td-none">
          <Col lg={{ span: 4, offset: 0 }} xs={{ span: 8, offset: 3 }} className="">
            © 2022 Valdilana
          </Col>
          <Col lg={{ span: 1, offset: 4 }} xs={{ span: 6, offset: 0 }}>
            <Link to="/about" className="text-decoration-none text-light">
              About
            </Link>
          </Col>
          <Col lg={{ span: 1, offset: 0 }} xs={{ span: 6, offset: 0 }}>
            <Link to="/collection" className="text-decoration-none text-light">
              Collection
            </Link>
          </Col>
          <Col lg={{ span: 1, offset: 0 }} xs={{ span: 6, offset: 0 }}>
            <Link to="/catalog" className="text-decoration-none text-light">
              Catalog
            </Link>
          </Col>
          <Col lg={{ span: 1, offset: 0 }} xs={{ span: 6, offset: 0 }}>
            <Link to="/contact" className="text-decoration-none text-light">
              Contact
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LandingFooter;
