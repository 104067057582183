import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import useWindowDimensions from "../WindowDimensions";

const LandingLogo = ({ setError }) => {
  return (
    <div>
      <Container fluid className="position-absolute text-center top-0 start-0" style={{ zIndex: 1000 }}>
        <Container className="m-0 p-0" fluid>
          <Row xs={1}>
            <Col xs={{ span: 6, offset: 0 }} sm={{ span: 4, offset: 4 }}>
              <Link className="text-decoration-none valdilana-font" to="/">
                <Row>
                  <Col>
                    <span className="display-1 text-decoration-none text-light valdilana-font">VALDILANA</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className="lead text-light valdilana-font">FABRIC MADE IN ITALY</span>
                  </Col>
                </Row>
              </Link>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default LandingLogo;
