import React from "react";
import Container from "react-bootstrap/Container";
import LandingPage from "./landing/LandingPage";
import LandingVideo from "./landing/LandingVideo";
import LandingButtons from "./landing/LandingButtons";

const GuestHomePage = ({ setError }) => {
  return (
    <div>
      <Container fluid className="m-0 p-0" style={{ backgroundColor: "#000", overflow: "hidden" }}>
        <Container fluid className="min-vh-100 p-0 position-static">
          <LandingVideo />
          <LandingButtons setError={setError} />
        </Container>
        <Container fluid className="m-0 p-0">
          <LandingPage setError={setError} />
        </Container>
      </Container>
    </div>
  );
};

export default GuestHomePage;
