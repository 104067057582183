import React from "react";
import Container from "react-bootstrap/Container";

const LandingVideo = ({ setError }) => {
  return (
    <div>
      <Container fluid className="position-absolute top-0 start-0 m-0 p-0 h-100">
        <video className="  w-100 h-100  " playsInline autoPlay={true} muted={true} loop id="asmHome" style={{ objectFit: "cover", zIndex: -1 }}>
          <source src="valdilanahero.mp4" type="video/mp4" />
        </video>
      </Container>
    </div>
  );
};

export default LandingVideo;
