import React from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";

const LandingHeader = ({ setToken, setIsAdmin }) => {
  return (
    <Navbar bg="none" expand="lg" className="navbar-dark text-light position-absolute top-0 end-0" style={{ zIndex: 100000 }}>
      <Container className="px-2 mx-2">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto text-dark">
            <Nav.Link>
              <Link to="/about" className="nav-link">
                ABOUT
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link to="/collection" className="nav-link">
                COLLECTION
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link to="/catalog" className="nav-link">
                CATALOG
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link to="/contact" className="nav-link">
                CONTACT
              </Link>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default LandingHeader;
